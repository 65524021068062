import { AlertColor } from '@mui/material';

export type BetaUser = {
	username: string;
	email: string;
	timestamp: Date;
};

export enum ErrorType {
	EmailInvalid,
	UsernameInvalid,
	UsernameNotAvailable,
}

export type Error = {
	type: ErrorType;
	message: string;
};

export type Alert = {
	severity: AlertColor;
	message: string | React.ReactNode;
};
