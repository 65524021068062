import { Button } from '@mui/material';
import { memo } from 'react';

const Footer = () => {
	return (
		<div className='footer'>
			<a href={process.env.REACT_APP_CONTACT_US}>
				<Button
					className='contact-us-button'
					variant='contained'
					type='submit'
				>
					Contact Us 📣
				</Button>
			</a>
			<p
				style={{
					marginTop: 40,
					marginBottom: 20,
				}}
			>
				© {2024} birdhaus Platforms, LLC. All Rights Reserved.
			</p>
			<p
				style={{
					marginTop: 20,
					marginBottom: 20,
				}}
			>
				Made with ❤️ in NYC 🗽
			</p>
		</div>
	);
};

export default memo(Footer);
