import { Stack } from '@mui/material';
import React, { lazy, memo, Suspense } from 'react';

const AnimatedAvatar = lazy(
	() =>
		import(/* webpackChunkName: "LazyAnimatedAvatar" */ './animated-avatar')
);
const Hero = () => {
	return (
		<h1 id='hero-text' style={{ paddingBottom: '10px' }}>
			<span className='bold highlight'>random,</span>
			<br />
			<Stack direction='row' alignItems='baseline'>
				<span
					className='bold highlight'
					style={{
						position: 'relative',
					}}
				>
					an
				</span>
				<Suspense
					fallback={
						<span
							className='bold highlight'
							style={{
								position: 'relative',
							}}
						>
							o
						</span>
					}
				>
					<AnimatedAvatar />
				</Suspense>

				<span
					className='bold highlight'
					style={{
						position: 'relative',
					}}
				>
					nymous,
				</span>
			</Stack>
			<span className='bold highlight'>hyper-global.</span>
			<br />
			<br />
			<span className='bold highlight'>
				<span className='highlight-4'>Migrate</span> all
			</span>
			<br />
			<span className='bold highlight'>over the world</span>
			<br />
			<span className='bold highlight'>
				and <span className='highlight-3'>find your flock</span>.
			</span>
		</h1>
	);
};

export default memo(Hero);
