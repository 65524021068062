/* Validation rules for different content types. */
enum ValidationRule {
  Username,
  Email,
}

/* Singleton class to validate stuff (email, username, etc.) */
class Validator {
  readonly USERNAME_MINIMUM_LENGTH = 2;
  readonly USERNAME_MAXIMUM_LENGTH = 32;
  readonly USERNAME_REGEX = /^[a-zA-Z0-9_]+$/;
  readonly EMAIL_REGEX = /^\S+@\S+\.\S+$/;

  static instance: Validator;

  private constructor() {}

  public static getInstance(): Validator {
    if (!Validator.instance) Validator.instance = new Validator();

    return Validator.instance;
  }

  /**
   * Driver that handles the actual input-validation logic.
   *
   * @param content — Some content to validate.
   * @param rule — ValidationRule to use.
   *
   * @returns `true` if content conforms to validation rule.
   */
  private validate = (content: any, rule: ValidationRule): boolean => {
    switch (rule) {
      case ValidationRule.Username: {
        return (
          this.USERNAME_REGEX.test(String(content)) &&
          String(content).length > this.USERNAME_MINIMUM_LENGTH &&
          String(content).length < this.USERNAME_MAXIMUM_LENGTH
        );
      }
      case ValidationRule.Email: {
        return this.EMAIL_REGEX.test(String(content));
      }
    }
  };

  public validateEmail = (email: string): boolean =>
    this.validate(email, ValidationRule.Email);

  public validateUsername = (username: string): boolean =>
    this.validate(username, ValidationRule.Username);
}

export default Validator.getInstance();
