import './assets/scss/App.scss';

import { ThemeProvider } from '@mui/material';
import { lazy, Suspense } from 'react';

import theme from './assets/Theme';
import Home from './components/Home';

const Map = lazy(
	() => import(/* webpackChunkName: "LazyMapChunk" */ './components/map/map')
);
const App = () => {
	return (
		<ThemeProvider theme={theme}>
			<Suspense>
				<Map />
			</Suspense>
			<Home />
		</ThemeProvider>
	);
};

export default App;
