import { initializeApp } from 'firebase/app';
import {
	collection,
	getDocs,
	getFirestore,
	query,
	where,
} from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

import constants from '../utils/Constants';

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_TOKEN,
	authDomain: 'away-885d7.firebaseapp.com',
	projectId: 'away-885d7',
	databaseURL: 'https://away-885d7-default-rtdb.firebaseio.com/',
	storageBucket: 'away-885d7.appspot.com',
	messagingSenderId: '1062375056447',
	appId: '1:1062375056447:web:6c35a3af9b93db5258848a',
	measurementId: 'G-67N4RRT2VJ',
};
const app = initializeApp(firebaseConfig);

export const firestore = getFirestore(app);
export const functions = getFunctions(app);

export default app;

export const isUsernameAvailable = async (
	username: string
): Promise<boolean> => {
	const q = query(
		collection(firestore, constants.firestoreCollectionPath),
		where('username', '==', username)
	);

	const querySnapshot = await getDocs(q);

	return querySnapshot.empty;
};

export const isEmailAvailable = async (email: string): Promise<boolean> => {
	const q = query(
		collection(firestore, constants.firestoreCollectionPath),
		where('email', '==', email)
	);

	const querySnapshot = await getDocs(q);

	return querySnapshot.empty;
};
