import { createTheme } from "@mui/material/styles";

import colors from "./scss/_colors.scss";
import fonts from "./scss/_fonts.scss";

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          "&& .MuiSvgIcon-root": {
            transition: "padding-left 0.12s",
          },
          "&:hover": {
            "&& .MuiSvgIcon-root": {},
            color: "white",
            backgroundColor: colors["p-400"],
            paddingLeft: "56%",
            borderColor: "transparent",
            boxShadow: "0px 0px 24px 6px #2a44a8",
          },
          "&:active": {
            paddingLeft: "63%",
          },

          boxShadow: "0px 0px 24px 6px transparent",
          color: colors["p-300"],
          border: `2px solid rgba(255, 255, 255, 0.14)`,
          backgroundColor: "transparent",
          borderRadius: 12,
          fontFamily: fonts["body"],
          fontSize: "1.3rem",
          fontWeight: 500,
          letterSpacing: "-0.6px",
          paddingLeft: 25,
          paddingRight: 25,
          textTransform: "lowercase",
          transition: "all 0.15s",
        },
      },
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            "&& .MuiSvgIcon-root": {
              transition: "padding-left 0.12s",
            },
            "&:hover": {
              "&& .MuiSvgIcon-root": {},
              color: "white",
              backgroundColor: colors["s-600"],
              paddingLeft: "14%",
              borderColor: "transparent",
              boxShadow: "0px 0px 24px 6px #484863",
            },
            "&:active": {
              paddingLeft: "15%",
            },

            boxShadow: "0px 0px 24px 6px transparent",
            color: colors["s-400"],
            border: `2px solid rgba(255, 255, 255, 0.08)`,
            backgroundColor: "transparent",
            borderRadius: 12,
            fontFamily: fonts["body"],
            fontSize: "1rem",
            fontWeight: 500,
            letterSpacing: "-0.6px",
            paddingLeft: 25,
            paddingRight: 25,
            textTransform: "lowercase",
            transition: "all 0.15s",
          },
        },
      ],
    },
  },
});

export default theme;
