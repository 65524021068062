import { ArrowForward as SignupIcon } from '@mui/icons-material';
import { Alert, Button, Stack } from '@mui/material';
import DOMPurify from 'dompurify';
import { addDoc, collection } from 'firebase/firestore';
import {
	ErrorMessage,
	Field,
	Form as FormikForm,
	Formik,
	FormikValues,
} from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';

import {
	firestore,
	isEmailAvailable,
	isUsernameAvailable,
} from '../api/Firebase';
import constants from '../utils/Constants';
import { BetaUser, Error, ErrorType } from '../utils/types';
import Validator from '../utils/Validator';

type Props = {
	onSuccess: any;
};

const SignupForm = (props: Props) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [username, setUsername] = useState('');
	const [email, setEmail] = useState('');
	const [error, setError] = useState<Error | null>(null);

	const validationSchema = Yup.object().shape({
		username: Yup.string()
			.matches(
				Validator.USERNAME_REGEX,
				'Only letters, numbers and underscores allowed'
			)
			.required('Enter your desired username'),
		email: Yup.string().email('Invalid email').required('Enter your email'),
	});

	const onUsernameChange = (value: string) => {
		const usernameErrorActive =
			(error && error.type == ErrorType.UsernameNotAvailable) ||
			ErrorType.UsernameInvalid;

		if (usernameErrorActive) setError(null);
		setUsername(value);
	};

	const onEmailChange = (value: string) => {
		const emailErrorActive = error && error.type == ErrorType.EmailInvalid;

		if (emailErrorActive) setError(null);
		setEmail(value);
	};

	const onSubmit = (values: FormikValues, actions: any) => {
		const cleanValues = {
			username: DOMPurify.sanitize(values.username),
			email: DOMPurify.sanitize(values.email),
		};
		const validUsername = Validator.validateUsername(cleanValues.username);
		const validEmail = Validator.validateEmail(cleanValues.email);

		const betaUser: BetaUser = {
			username: cleanValues.username,
			email: cleanValues.email,
			timestamp: new Date(),
		};

		setIsSubmitting(true);

		if (validUsername && validEmail) {
			isEmailAvailable(cleanValues.email).then((success) => {
				if (success) {
					isUsernameAvailable(cleanValues.username).then(
						(success) => {
							if (success) {
								addDoc(
									collection(
										firestore,
										constants.firestoreCollectionPath
									),
									betaUser
								)
									.then(() => {
										setIsSubmitting(false);
										actions.setSubmitting(false);
										props.onSuccess();
									})
									.catch((e) => {
										setIsSubmitting(false);
										actions.setSubmitting(false);
										console.log(e);
									});
							} else {
								setIsSubmitting(false);
								setError({
									type: ErrorType.UsernameNotAvailable,
									message: `@${username} is not available.`,
								});

								actions.setSubmitting(false);
							}
						}
					);
				} else {
					setIsSubmitting(false);
					setError({
						type: ErrorType.EmailInvalid,
						message: 'Email already in use.',
					});

					actions.setSubmitting(false);
				}
			});
		}
	};

	return (
		<Formik
			initialValues={{
				username: '',
				email: '',
			}}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
		>
			<FormikForm autoComplete='off' spellCheck={false}>
				<Stack alignItems='flex-start'>
					<div className='signup-form-field-wrapper'>
						<Field
							className='signup-form-field'
							name='username'
							type='text'
							placeholder='Desired username'
							validate={onUsernameChange}
						/>
						<label className='signup-form-label'>
							desired username
						</label>
					</div>

					<div className='signup-form-field-error'>
						<ErrorMessage name='username' />
					</div>

					<div className='signup-form-field-wrapper'>
						<Field
							className='signup-form-field'
							name='email'
							type='email'
							placeholder='Email'
							validate={onEmailChange}
						/>
						<label className='signup-form-label'>email</label>
					</div>

					<div className='signup-form-field-error'>
						<ErrorMessage name='email' />
					</div>

					{error ? (
						<Alert
							severity='error'
							color='warning'
							style={{
								marginTop: 25,
							}}
						>
							{error ? error.message : null}
						</Alert>
					) : null}

					<Button
						className='signup-button'
						disabled={isSubmitting}
						endIcon={<SignupIcon />}
						type='submit'
						style={{
							marginTop: 30,
						}}
					>
						Sign Up
					</Button>
				</Stack>
			</FormikForm>
		</Formik>
	);
};

export default SignupForm;
